<template>
  <div class="info">
    <div class="info-column">
      <h3>Ihre Seamless Writing Experience</h3>
      <div class="description">
        <h3 class="item-info-header">Info</h3>
        <p>
          {{ info.description }}
        </p>
        <h3 class="item-info-header">Umfang</h3>
        <p>
          {{ info.scope }}
        </p>
        <h3 class="item-info-header">Links</h3>
        <p>
          {{ info.links }}
        </p>
        <h3 class="item-info-header">SEO?</h3>
        <p>
          {{ info.seo }}
        </p>
        <h3 class="item-info-header">Beinhaltet:</h3>
        <p>
          {{ info.pieces }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  computed: {
    info() {
      if (this.$store.state.shownInfo) {
        return this.$store.state.shownInfo;
      } else return null;
    },
  },
};
</script>
