import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue"
import Legal from "../views/Legal.vue"
import Generator from "../views/Generator.vue";
import Offer from "../views/Services.vue";
import Team from "../views/Team.vue";
import About from "../views/About.vue"
import Clients from "../views/Clients.vue";
import Carroussel from "../views/Carroussel.vue"
import Contact from "../views/Contact.vue";
import Scroller from "../views/Scroller.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/services",
    name: "Services",
    component: Offer,
  },
  {
    path: "/generator",
    name: "Generator",
    component: Generator,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal,
  },
  {
    path: "/scroller",
    name: "Scroller",
    component: Scroller,
  },
  {
    path: "/carroussel",
    name: "Carroussel",
    component: Carroussel,
  },
  {
    path: '/:catchAll(.*)*',
    name: "NotFound",
    component: NotFound,
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  
});

export default router;
