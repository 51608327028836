<template>
  <div>
    <TheHeaderMobile />
    <TheHeader />
    <MenuHeaderTabed />
    
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";


export default {
  name: "Contact",
  components: {
    TheHeader,
    TheHeaderMobile,
    MenuHeaderTabed,
  },
  data() {
    return {
      PageHeader:"Kontakt"
    };
  },
};
</script>
