<template>
  <div class="client">
    <div class="client-header">
      <h1>{{clients.name}}</h1>
      <h3>{{clients.jobDescription}}</h3>
    </div>
    <div class="client-description">
      <b>
        {{clients.ownerName}}</b>
        <br>
        <br>
      {{clients.ownerBlurb}}
    </div>
    <div class="client-service-button">Services</div>
    <div class="client-account">
      {{clients.accountDescription}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientColumn",
  props: {
    clients: Object,
  },
  data() {
    return {
    };
  },
  
};
</script>
