<template>
  <div
    class="backdrop-mobile mobile"
    :class="{ 'backdrop-mobile-close': opened === false }"
    @click="toggleMobileItemOrInfo()"
  />
</template>
<script>
export default {
  name: "BackdropMobile",
  computed: {
    opened() {
      return (this.$store.state.showItemsMobile || this.$store.state.showInfoMobile);
    },
  },
  methods: {
    toggleMobileItemOrInfo() {
      this.$store.commit("toggleMobileItemOrInfo");
    },
  },
};
</script>
