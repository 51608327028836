<template>
  <div id="copywriter" class="about-paragraph long">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">03</div>
      <div class="paragraph-header">
        <h3>Warum so wenig Texter*innen?</h3>
      </div>
    </div>
    <div id="copywriter-content" class="paragraph-content">
      <p>
        Unternehmen und Agenturen suchen gerade, entschuldigt bitte diese
        Ausdrucksweise, wie bescheuert nach Menschen, die schreiben können. Bei
        LinkedIn sind seit Jahren jeden Tag mehrere tausend Stellen in
        Deutschland unbesetzt, ob Copywriter, Content Writer, Community Manager,
        Social Media Contentwriter, Blog-Autor*innen. Unser Fazit dieser
        Entwicklung?
      </p>
      <p>
        <b>Es gibt:</b>
      </p>
      <div class="left-paragraph">
        <div id="a" class="glowing-index">a</div>
        <p>
          … zu wenige Menschen, die schreiben, also sich schriftlich ausdrücken
          können und bereit sind, sich mit den Ideen, Visionen und Vorgaben von
          Kund*innen auseinanderzusetzen
        </p>
      </div>
      <div class="right-paragraph">
        <div id="b" class="glowing-index b">b</div>
        <p>
          … zu geringe Wertschätzung für die Aufgabe des Schreibens durch
          Arbeitgeber*innen und Kund*innen. „Schreiben? Kann ich doch selbst,
          habe ich in der Schule gelernt. Sogar meine Bachelor-Arbeit habe ich
          selbst geschrieben.“ - diese Mentalität ist leider noch zu verbreitet.
        </p>
      </div>
      <div class="left-paragraph">
        <span id="c" class="glowing-index">c</span>
        <p>
          … wenig Verständnis über die eigentliche Arbeit von Texter*innen.
          Recherche, Prokrastination, Feilen am Text, Feedbackschleifen von
          Kunden - all das fließt in einen Text ein und ist nicht trivial oder
          Zeitverschwendung. Copywriter müssen lesen und Informationen aufsaugen
          - unser Arbeitsspeicher ist stets gefüllt.
        </p>
      </div>
      <div class="right-paragraph">
        <span id="d" class="glowing-index d">d</span>
        <p>
          … eine Kultur, die nur Sichtbares belohnt. Jeden Tag acht Stunden im
          Büro zu sein, funktioniert ja so schon nicht. Texter*innen leiden
          besonders unter räumlichen und zeitlichen Restriktionen, denn
          Kreativität und die nötige Gelassenheit einen Textfluss zu erzeugen,
          erreichen wir nicht hinter einen Bildschirm gekettet. Wir wollen auch
          mal Quatschen, aber die ständige Verfügbarkeit macht Kreativität
          zunichte. Schreibende müssen in Klausur gehen, sich zurückziehen , die
          Stimme des Textes in ihrem Kopf laut hören können, bevor sie aufs
          Blatt kommt.
        </p>
      </div>

      <div class="left-paragraph">
        <span id="e" class="glowing-index">e</span>
        <p>
          … eine Entwicklung in Schulbildung, der Akademie und der Gesellschaft,
          die behauptet, dass Textverständnis schon genüge und man den Rest
          visuell oder audiovisuell vermitteln könnte. Aus diesem Grund ist der
          Markt von Art Directors überflutet und die Rahmenbedingungen von
          Arbeit an ihnen, Projektmanager*innen, Strategists und
          Account-Manager*innen ausgerichtet. Wer guten Text will, muss Texte
          lieben und nicht nur das Lesen, sondern auch das Schreiben fördern.
        </p>
      </div>
      <div class="left-paragraph">
        <p>
          Deshalb gibt es Wirklichkeitsdichte. Wir wollen die Wertschätzung von
          Schreibenden in der Gesellschaft und auf dem Arbeitsmarkt erhöhen, die
          Arbeitskultur und bestehenden Strukturen so positiv beeinflussen, dass
          Burnout, Überforderung, Zwang und Stress nicht zum Arbeitsalltag einer
          Texterin gehören und Kund:innen den Copywriter bieten, den sie schon
          lange suchen. Nicht mehr, nicht weniger.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    this.handleResize();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize, false);
  },

  created() {},
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document
        .getElementById("copywriter")
        .getBoundingClientRect();
      if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "copywriter-tab");
      }

      const canvas = document.getElementById("canvas-copywriter");
      const ctx = canvas.getContext("2d");
      // let copywriter = document.getElementById("copywriter").getBoundingClientRect();
      // let about = document.getElementById("about-container");
      //let copywriter = document.getElementById("copywriter");

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      this.drawStuff(ctx);
    },

    handleResize() {
      // creating a dynamically resizable canvas
      const canvas = document.getElementById("canvas-copywriter");
      const ctx = canvas.getContext("2d");
      // let copywriter = document.getElementById("copywriter").getBoundingClientRect();
      // let about = document.getElementById("about-container");
      //let copywriter = document.getElementById("copywriter");

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      this.drawStuff(ctx);
    },

    drawStuff(ctx) {
      let a = this.getBoundingCenter(document.getElementById("a"));
      let b = this.getBoundingCenter(document.getElementById("b"));
      let c = this.getBoundingCenter(document.getElementById("c"));
      let d = this.getBoundingCenter(document.getElementById("d"));
      let e = this.getBoundingCenter(document.getElementById("e"));

      ctx.beginPath();
      ctx.moveTo(a.x, a.y);
      ctx.lineTo(b.x, b.y);
      ctx.lineTo(c.x, c.y);
      ctx.lineTo(d.x, d.y);
      ctx.lineTo(e.x, e.y);

      ctx.lineWidth = 1;

      ctx.strokeStyle = "black";
      ctx.stroke();
    },

    getBoundingCenter(element) {
      let centerCoord = {
        x: element.getBoundingClientRect().x + window.pageXOffset + (element.getBoundingClientRect().width / 2),
        y: element.getBoundingClientRect().y + window.pageYOffset + (element.getBoundingClientRect().height / 2),
      };
      return centerCoord;
    },
  },
};
</script>
