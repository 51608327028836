<template>
  <div>
    <BackdropMobile />
    <Backdrop />
    <DetailModalMobile />
    <InfoMobile />
    <ItemsMobile />
    <TheHeaderMobile />
    <TheHeader />
    <MenuHeaderTabed />
    <Editor />
  </div>
</template>

<script>
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import TheHeader from "../components/TheHeader.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import Editor from "../components/generator/Editor.vue";
import InfoMobile from "../components/generator/InfoMobile.vue";
import ItemsMobile from "../components/generator/ItemsMobile.vue";
import BackdropMobile from "../components/generator/BackdropMobile.vue";
import Backdrop from "../components/generator/Backdrop.vue";
import DetailModalMobile from "../components/generator/DetailModalMobile.vue";

export default {
  name: "Generator",
  components: {
    TheHeaderMobile,
    TheHeader,
    MenuHeaderTabed,
    Editor,
    InfoMobile,
    ItemsMobile,
    BackdropMobile,
    Backdrop,
    DetailModalMobile,
  },
  data() {
    return {};
  },
  computed: {
    opened() {
      console.log(this.$store.state.showItemsMobile);
      return this.$store.state.showItemsMobile;
    },
    infoOpened() {
      return this.$store.state.showInfoMobile;
    },
  },
  methods: {
    toggleMobileItemList() {
      this.$store.commit("toggleMobileList");
    },
  },
};
</script>
