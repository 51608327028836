<template>
  <div class="profile">
    <div class="profile-pic-container">
      <div class="profile-pic"></div>
      <h1 class="profile-name">{{ profiles.name }}</h1>
    </div>
    <div class="profile-text">
      <div>
        <p>
          {{ profiles.description }}
        </p>
        <p>
          {{ profiles.job }}
        </p>
      </div>

      <div class="profile-contact-button">Kontaktieren</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    profiles: Object,
  },
};
</script>
