<template>
  <div class="about-header-container-narrow">
    <TheHeader />
    <div class="about-header-tabed-narrow">
      <h2>{{ "Über uns" }}</h2>
      <nav class="nav-tabs large">
        <a>{{ getActive.title }}</a>
        <div class="paragraph-chapter-id-narrow">{{ getActive.index }}</div>
      </nav>
    </div>
  </div>
</template>

<script>
import TheHeader from "../TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
  computed: {
    getActive() {

        let tab = {
            title : "Das bist Du",
            index: "01",
        }
      
      switch (this.$store.getters.getVisibleChapter) {
        case "you-tab":
          tab.title = "Das bist Du";
          tab.index = "01";
          break;
        case "text-tab":
          tab.title = "Warum so viel Text?";
          tab.index = "02";
          break;
        case "copywriter-tab":
          tab.title = "Warum so wenig Texter*innen?";
          tab.index = "03";
          break;
        case "textperson-tab":
          tab.title = "Wir sind Eure Textperson";
          tab.index = "04";
          break;
        case "wkd-tab":
          tab.title = "Was bitte ist Wirklichkeitsdichte?";
          tab.index = "05";
          break;
        case "textheer-tab":
          tab.title = "Textheer";
          tab.index = "06";
          break;
        case "wkd18-tab":
          tab.title = "Wirklichkeitsdichte in 18 Zeilen";
          tab.index = "07";
          break;
        default:
          tab.title = "Das bist Du";
          tab.index = "01";
      }
      return tab;
    },
  },
  methods: {
    scrollInto(hash) {
      let element = document.getElementById(hash);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
};
</script>
