<template>
  <div>
    <canvas id="canvas-copywriter" class="canvas-copywriter"></canvas>
    <AboutHeaderTabed />
    <AboutHeaderNarrow />
    <AboutContent />
  </div>
</template>

<script>


import AboutHeaderTabed from "../components/about/AboutHeaderTabed.vue";
import AboutHeaderNarrow from "../components/about/AboutHeaderNarrow.vue";
import AboutContent from "../components/about/AboutContent.vue";

export default {
  name: "Home",
  components: {
    
    
    AboutHeaderTabed,
    AboutHeaderNarrow,
    AboutContent,
  },
  data() {
    return {
      PageHeader: "Über uns",
      methods: {
      },
    };
  },
};
</script>
