<template>
  <div class="about-header-container">
    <TheHeaderInvert />
    <div class="about-header-tabed invert legal">
      <nav class="nav-tabs-inverted large">
        <a
          id="imprint-tab"
          :class="{ invertselected: this.getActive === 'imprint-tab' }"
          @click="scrollInto('imprint')"
          >Impressum</a
        >
        <a
          id="privacy-tab"
          :class="{ invertselected: this.getActive === 'privacy-tab' }"
          @click="scrollInto('privacy')"
          >Datenschutz</a
        >
        <a
          id="agb-tab"
          :class="{ invertselected: this.getActive === 'agb-tab' }"
          @click="scrollInto('agb')"
          >AGB</a
        >
      </nav>
    </div>
  </div>
</template>

<script>
import TheHeaderInvert from "../TheHeaderInvert.vue";
export default {
  components: {
    TheHeaderInvert,
  },
  computed: {
    getActive() {
      return this.$store.getters.getVisibleChapterLegal;
    },
  },
  data() {
    return {
      selectedId: "imprint-tab",
    };
  },
  methods: {
    scrollInto(hash) {
      let element = document.getElementById(hash);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    markSelected(hash) {
      // remove class from previously selected tab
      document.getElementById(this.selectedId).classList.remove("invertselected");
      this.selectedId = hash;

      document.getElementById(hash).classList.add("invertselected");

      console.log(this.selectedId);
    },
  },
};
</script>
