<template>
  <div class="about-header-container">
    <TheHeader />
    <div class="about-header-tabed">
      <h2>Über uns</h2>
      <nav class="nav-tabs large">
        <a
          id="you-tab"
          :class="{ selected: getActive === 'you-tab' }"
          @click="scrollInto('you')"
          >Das bist Du</a
        >
        <a
          id="text-tab"
          :class="{ selected: getActive === 'text-tab' }"
          @click="scrollInto('text')"
          >Warum so viel Text?</a
        >
        <a
          id="copywriter-tab"
          :class="{ selected: getActive === 'copywriter-tab' }"
          @click="scrollInto('copywriter')"
          >Warum so wenig Texter*innen?</a
        >
        <a
          id="textperson-tab"
          :class="{ selected: getActive === 'textperson-tab' }"
          @click="scrollInto('textperson')"
          >Wir sind Eure Textperson</a
        >
        <a
          id="wkd-tab"
          :class="{ selected: getActive === 'wkd-tab' }"
          @click="scrollInto('wkd')"
          >Was bitte ist Wirklichkeitsdichte?</a
        >
        <a
          id="textheer-tab"
          :class="{ selected: getActive === 'textheer-tab' }"
          @click="scrollInto('textheer')"
          >Textheer</a
        >
        <a
          id="wkd18-tab"
          :class="{ selected: getActive === 'wkd18-tab' }"
          @click="scrollInto('wkd18')"
          >Wirklichkeitsdichte in 18 Zeilen</a
        >
      </nav>
    </div>
  </div>
</template>

<script>
import TheHeader from "../TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
  data() {
    return {
      selectedId: "you-tab",
    };
  },
  computed: {
    getActive() {
      return this.$store.getters.getVisibleChapter;
    },
  },
  methods: {
    scrollInto(hash) {
      let element = document.getElementById(hash);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      this.markSelected(hash + "-tab");
    },
    markSelected(hash) {
      // remove class from previously selected tab
      document.getElementById(this.selectedId).classList.remove("selected");
      this.selectedId = hash;

      document.getElementById(hash).classList.add("selected");

      console.log(this.selectedId);
    },
  },
};
</script>
