<template>
  <div>
    <div id="main-container" class="main-container">
      <MenuHeaderTabed />
      <ServicesContent id="services" />
      <CarrousselContent id="offerings" @scrollblock="scrollBlock" />
      <TeamContainer id="team" />
    </div>
    <ClientContent id="clients" />
    <LegalBanner />
  </div>
</template>

<script>
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import ServicesContent from "../components/services/ServicesContent.vue";
import CarrousselContent from "../components/carroussel/CarrousselContent.vue";
import TeamContainer from "../components/team/TeamContainer.vue";
import ClientContent from "../components/clients/ClientContent.vue";
import LegalBanner from "../components/about/LegalBanner.vue";

export default {
  name: "Scroller",
  components: {
    MenuHeaderTabed,
    ServicesContent,
    CarrousselContent,
    TeamContainer,
    ClientContent,
    LegalBanner,
  },
  data() {
    return {
      blocked: true,
    };
  },
  computed: {
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      let id = this.$store.getters.getVisibleChapterMain.slice(0, -4); // get active tab and get rid of the '-tab' suffix
      let center = document.getElementById(id);
      center.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    },
    scrollBlock() {
      const root = document.body;
      this.blocked = !this.blocked;
      console.log(this.blocked);
      if (this.blocked) {
        root.classList.remove("scrollblock");
      } else {
        root.classList.add("scrollblock");
      }
    },
  },
};
</script>
