<template>
  <div class="editor-container-desktop-mobile">
    <div class="editor-container row large">
      <Info class="info" />

      <DetailModal />
      <OfferingEdit />
      

      <Items class="editor-items" />
      <ItemsMobile class ="editor-items-mobile"/>

    </div>
  </div>
</template>

<script>
import OfferingEdit from "./OfferingEdit.vue";
import DetailModal from "./DetailModal.vue"
import Info from "./Info.vue";
import Items from "./Items.vue";
import ItemsMobile from "./ItemsMobile.vue";


export default {
  components: {
    OfferingEdit,
    DetailModal,
    Info,
    Items,
    ItemsMobile
    
  },
};
</script>
