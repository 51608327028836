<template>
  <div>
    <TheHeaderMobile />
    <TheHeader />
    <MenuHeaderTabed />
    <ServicesContent />
  </div>
</template>

<script>
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import TheHeader from "../components/TheHeader.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import ServicesContent from "../components/services/ServicesContent.vue";

export default {
  name: "Services",
  components: {
    TheHeader,
    MenuHeaderTabed,
    TheHeaderMobile,
    ServicesContent,
  },
  data() {
    return {
    };
  },
};
</script>
