<template>
  <div id="wkd18" class="about-paragraph">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">07</div>
      <div class="paragraph-header">
        <h3>Wirklichkeitsdichte in 18 Zeilen</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p>
        Die Wirklichkeitsdichte GmbH ist eine Agentur für textbasierten Content
        und alle sich daraus ableitenden Kreationen. Betrieben seit 2014 von
        Peter Wagner unter dem vormaligen Namen Creative Creations and
        Consultimg (Tippfehler des Gewerbeamtes), seit April 2021 unter dem
        Namen Wirklichkeitsdichte und gegründet mit Hannes Wagner und Rebecca
        Irvine am 26.10.2021.
      </p>
      <p>
        Wir wollen Menschen gut für schwierige Arbeit wie Text, Bild, Grafik,
        Film, Musik bezahlen. Wir wollen Schreibenden ein Einkommen zahlen, mit
        dem Sie mit einigen, gut geschriebenen Text ihre Miete und
        Lebenshaltungskosten zahlen können. Wir wollen die Arbeitskultur in
        Agenturen und Unternehmen kreativenfreundlicher gestalten und ein
        Zeichen für den Kulturwandel sein.
      </p>
      <p>
        Unsere Kunden helfen uns dabei. Sie wagen es, eine andere Form der
        Zusammenarbeit zu beginnen, sie vertrauen in unsere Professionalität,
        sie unterstützen uns finanziell. Dafür erhalten sie qualitative Texte,
        einen einfachen Workflow, eine overheadarme Zusammenarbeit und
        konsistente Ansprechpartner*innen für ihre Themen, die kontinuierlich
        und ausfallsfrei für sie verfügbar sind.
      </p>
    </div>
  </div>
</template>
<script>

export default {
  
  mounted() {
    this.handleScroll();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document.getElementById("wkd18").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "wkd18-tab");
      }
    },
  },
};
</script>
