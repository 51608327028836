<template>
  <div id="carroussel-container" class="carroussel-container" :style="gradient">
    <CarrousselHeader v-if="!generatorActive" />
    <div
      v-if="!generatorActive"
      id="carroussel-column-container"
      class="carroussel-column-container"
    >
      <CarrousselColumn
        v-for="(carroussel, index) in carroussel"
        :key="index"
        :carroussel="carroussel"
        v-on:in-view="selectGradientColor"
        @clicked="toggleGenerator"
      />
    </div>
    <CarrousselFooter :dots="dots" :active="active" v-if="!generatorActive" />

    <div class="editor-container-desktop-mobile" v-if="generatorActive">
      <div class="info-close-generator" @click="toggleGenerator">
        <img src="~@/assets/arrow-left-black.svg" />
      </div>
      <Info v-if="generatorActive" />
      <DetailModal v-if="generatorActive" />
      <OfferingEdit v-if="generatorActive" @close-generator="toggleGenerator" />
      <Items v-if="generatorActive" />
      <ItemsMobile v-if="generatorActive" />
      <InfoMobile v-if="generatorActive" />
    </div>
  </div>
</template>

<script>
import CarrousselColumn from "./CarrousselColumn.vue";
import CarrousselHeader from "./CarrousselHeader.vue";
import CarrousselFooter from "./CarrousselFooter.vue";

import Info from "../generator/Info.vue";
import DetailModal from "../generator/DetailModal.vue";
import OfferingEdit from "../generator/OfferingEdit.vue";
import Items from "../generator/Items.vue";
import ItemsMobile from "../generator/ItemsMobile.vue";
import InfoMobile from "../generator/InfoMobile.vue";

export default {
  name: "CarrousselContent",
  components: {
    CarrousselColumn,
    CarrousselHeader,
    CarrousselFooter,

    Info,
    DetailModal,
    OfferingEdit,
    Items,
    ItemsMobile,
    InfoMobile,
  },
  data() {
    return {
      translation: {},
      gradient: {},
      dots: this.$store.state.carroussel.length,
      active: 0,
      clicked: false,
      infomobile: this.$store.state.showInfoMobile,
    };
  },
  computed: {
    carroussel() {
      return this.$store.state.carroussel;
    },
    infoShown() {
      return this.$store.state.showInfoMobile;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);

    window.addEventListener("scroll", this.handleScroll);

    this.handleScroll();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (!this.generatorActive) {
        let elements = document.getElementsByClassName("carroussel-column");
        let center = elements[0];
        if (this.$store.getters.getVisibleChapterMain === "offerings-tab") {
          center.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "center",
          });
        }
      }
    },
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("offerings")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "offerings-tab");
        }
      }
    },

    selectGradientColor(id) {
      // set active tab for dots in footer
      this.active = id;

      if (id % 2 != 0) {
        this.gradient = {
          background:
            "transparent linear-gradient(0deg, #ff8900 0%, #ffffff00 60%) 0% 0% no-repeat padding-box",
        };
      } else {
        this.gradient = {
          background:
            "transparent linear-gradient(0deg, #651EE3 0%, #ffffff00 60%) 0% 0% no-repeat padding-box",
        };
      }
    },
    toggleGenerator() {
      // disable eventListener for focus scrolling
      window.removeEventListener("resize", this.handleResize);
      window.removeEventListener("resize", this.handleScroll);
      this.clicked = !this.clicked;
      this.$store.commit("toggleGenerator");
      // block any scrolling
      this.$emit("scrollblock", this.clicked);
    },
  },
};
</script>
