<template>
  <div id="textperson" class="about-paragraph">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">04</div>
      <div class="paragraph-header">
        <h3>Wir sind Eure Textperson</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p>
        Wir schreiben für Euch. Schon klar. Aber wir tun noch viel mehr als das.
        Stellt Euch vor, ihr könntet eine Person im Team haben, die immer
        ansprechbar für Texte und Textfragen ist, die an Meetings teilnimmt, die
        auch mit Kunden spricht, Feedback einholt, abstimmt, Texte liefert, viel
        mehr Texte liefert, als ihr jemals erhalten habt.
      </p>
      <p>
        Das ist Wirklichkeitsdichte. Ihr erhaltet von uns eine feste
        Ansprechpartnerin, die für Euch immer erreichbar ist. Dieser
        Ansprechpartner ist Projektmanagement, Lektorat, Strategy und
        Qualitätsmanagement in einem und schreibt auch Texter. Alle Texte, die
        diese Person nicht schafft, wird im Hintergrund von unserem Textheer
        produziert. Ausfallssicher, zuverlässig, qualitativ.
      </p>
      <p>
        So werden wir Euer neues Teammitglied. Dafür zahlt ihr einen festen
        Betrag im Monat, fast so wie ein Gehalt, nur besser, weil ihr euch
        keinen Kopf um Arbeitgeberbrutto, Versicherungen, Urlaubstage,
        Lohnsteuerbescheide oder anderes machen müsst. Ihr erhaltet eine
        vereinbarte Abnahmemenge an Texten, Formaten oder Stunden mit richtig
        profitablen Mengenrabatten. Und wenn ihr die ändern wollt oder nicht
        alles verbraucht, dann wird das alles zu einem Prepaid-Guthaben.
      </p>
      <p>
        Das Ergebnis: Ihr habt Euren Copywriter, wir haben glückliche
        Texterinnen. Ihr müsst Euch nicht mit Administration, Kündigung,
        Recruiting oder Burnouts rumschlagen, wir haben die Ehre als
        Wirklichkeitsdichte an Euch andocken zu dürfen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
       const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document.getElementById("textperson").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "textperson-tab");
      }
    },
  },
};
</script>
