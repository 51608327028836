<template>
  <li>
    <div class="offering-item">
      <span>{{ offerings.name }}</span>
      <div class="offering-price-container">
        <span class="offering-multiplier">
          {{ offerings.quantity + " x" }}</span
        >
        <span class="offering-counter">{{ offerings.price }}€</span>
        <img
          title="Angebot Details"
          class="detail-item"
          src="~@/assets/details.svg"
          @click="$emit('setInfo')"
        />
      </div>
    </div>
    <div class="count-delete">
      <!--- kommt später in den modal rein
      <span @click="$emit('decrement')"> - </span>
      <span> {{ offerings.quantity }} </span>
      <span @click="$emit('increment')"> + </span>
      --->

      <img
        title="Angebot entfernen"
        class="delete-item end"
        src="~@/assets/close.svg"
        @click="$emit('delete')"
      />
    </div>

  </li>
</template>
<script>

export default {
  name: "ChosenOffering",
  props: {
    offerings: Object,
  },
  data() {
    return {};
  },
};
</script>
