<template>
  <div>
    <TheHeaderMobile />
    <TheHeader />
    <MenuHeaderTabed />
    <div class="team">
    <TeamContainer />
    </div>

  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import TeamContainer from "../components/team/TeamContainer.vue";


export default {
  name: "Team",
  components: {
    TheHeader,
    TheHeaderMobile,
    MenuHeaderTabed,
    TeamContainer,
  },
  data() {
    return {
    };
  },
};
</script>
