<template>
  <div id="legal-container" class="legal-container">
    <Imprint id="imprint" />
    <hr/>
    <Privacy id="privacy" />
    <hr />
    <AGB id="agb" />
    <CopyrightBanner />
  </div>
</template>

<script>
import Imprint from "./Imprint.vue";
import Privacy from "./Privacy.vue";
import AGB from "./AGB.vue";
import CopyrightBanner from "./CopyrightBanner.vue";

export default {
  components: {
    Imprint,
    Privacy,
    AGB,
    CopyrightBanner,
  },
};
</script>
