<template>
  <div class="offering-edit-container">
    <div>
      <div class="offering-header large">
        <h2>Copywriter</h2>
        <span>Experience</span>
      </div>
      <div class="offering-header-mobile mobile">
        <div class="offering-price-mobile column">
          <div class="offering-edit-name row">
            <h2>Copywriter</h2>
          </div>
          <span>Experience</span>
        </div>
      </div>

      <div class="offering-price-top-container">
        <div class="close-editor-mobile" @click="$emit('close-generator')">
          <img src="~@/assets/arrow-left-black.svg" />
        </div>
        <div id="prices">
          <div class="offering-price row">
            <span>Basispreis</span>
            <span class="base-price">{{ price }} €</span>
          </div>
          <div class="offering-price row">
            <span>Rabatt</span>
            <span class="base-price-invert">{{ discount }} €</span>
          </div>
        </div>
      </div>

      <div class="item-list">
        <ul>
          <ChosenOffering
            v-for="(offerings, index) in offerings"
            :key="index"
            :offerings="offerings"
            @delete="deleteFromList(offerings.id)"
            @increment="incrementOffering(offerings.id)"
            @decrement="decrementOffering(offerings.id)"
            @setInfo="setInfo(offerings.id)"
          />
        </ul>
      </div>
      <div class="add-item-plus" @click="toggleMobileItemList()">
          <img src="~@/assets/plus_circle.svg" />
        </div>
    </div>
    <CallToAction />
    <CallToActionMobile />
  </div>
</template>
<script>
import ChosenOffering from "../generator/ChosenOffering.vue";
import CallToActionMobile from "./CallToActionMobile.vue";
import CallToAction from "./CallToAction.vue";

export default {
  components: {
    ChosenOffering,
    CallToActionMobile,
    CallToAction,
  },
  data() {
    return {
      //offerings: this.$store.getters.getChosenOfferings,
    };
  },
  computed: {
    opened() {
      return this.$store.state.showItemsMobile;
    },
    offerings() {
      return this.$store.getters.getChosenOfferings;
    },
    price() {
      return this.calculatePrice();
    },
    discount() {
      return this.calculateDiscount();
    },
  },
  methods: {
    toggleMobileItemList() {
      console.log("mobile item list toggle");
      this.$store.commit("toggleMobileList");
    },
    deleteFromList(index) {
      this.$store.commit("deleteFromList", index);
      this.$store.commit("resetQuantity", index);
    },
    setInfo(index) {
      this.$store.commit("setInfo", index);
      // this.$store.commit("toggleMobileInfo");
      this.$store.commit("toggleDetailModal");
    },
    incrementOffering(index) {
      this.$store.commit("addQuantity", index);
    },
    decrementOffering(index) {
      this.$store.commit("substractQuantity", index);
    },
    calculatePrice() {
      return this.offerings.reduce((total, offering) => {
        return total + offering.price * offering.quantity;
      }, 0);
    },
    calculateDiscount() {
      let discount = 0;
      let quantityList = {};

      this.offerings.map((offering) => {
        quantityList[offering.category] = offering.quantity;
      });

      console.log(quantityList);

      // blogposts
      if (quantityList.blogpost) {
        if (quantityList.blogpost > 1 && quantityList.blogpost <= 3) {
          discount += quantityList.blogpost * 25;
        }
        if (quantityList.blogpost > 3 && quantityList.blogpost <= 5) {
          discount += quantityList.blogpost * 50;
        }
        if (quantityList.blogpost > 5 && quantityList.blogpost <= 8) {
          discount += quantityList.blogpost * 75;
        }
        if (quantityList.blogpost > 8) {
          discount += quantityList.blogpost * 100;
        }
      }
      // pics 1
      if (quantityList.pic1 && quantityList.blogpost) {
        if (quantityList.blogpost > 1 && quantityList.blogpost <= 3) {
          discount += quantityList.blogpost * 18.5;
        }
        if (quantityList.blogpost > 3) {
          discount += quantityList.blogpost * 18.5;
        }
      }

      // pics 2
      if (quantityList.pic2 && quantityList.pic1 && quantityList.blogpost) {
        if (quantityList.blogpost <= 3) {
          discount += quantityList.blogpost * 19.5;
        }
        if (quantityList.blogpost > 3) {
          discount += quantityList.blogpost * 20;
        }
      }

      // graphics gebdungen an blogpost?
      if (quantityList.graphic && quantityList.blogpost) {
        if (quantityList.graphic > 4 && quantityList.graphic < 8) {
          discount += quantityList.blogpost * 10;
        }
        if (quantityList.graphic > 7) {
          discount += quantityList.graphic * 12.5;
        }
      }

      // deep dive
      if (quantityList.deepdive) {
        if (quantityList.deepdive == 2) {
          discount += quantityList.deepdive * 50;
        }
        if (quantityList.deepdive == 3) {
          discount += quantityList.deepdive * 65;
        }
        if (quantityList.deepdive > 3 && quantityList.deepdive < 8) {
          discount += quantityList.deepdive * 100;
        }
        if (quantityList.deepdive >= 8) {
          discount += 125;
        }
      }

      // social post
      if (quantityList.socialpost) {
        let off = this.$store.getters.getOfferingByCategory("socialpost");
        if (quantityList.socialpost > 3) {
          this.$store.commit("changePrice", { id: off.id, amount: 87.5 });
        }
        if (quantityList.socialpost < 4) {
          this.$store.commit("changePrice", { id: off.id, amount: 125 });
        }
      }

      // story post
      if (quantityList.storypost) {
        if (quantityList.storypost > 10) {
          discount += quantityList.storypost * 20;
        }
      }

      // additional hours
      if (quantityList.hours) {
        if (quantityList.hours >= 10 && quantityList.hours <= 20) {
          discount += quantityList.hours * 75;
        }
        if (quantityList.hours > 20) {
          discount += quantityList.hours * 68;
        }
      }

      console.log(discount);

      return discount;
    },
  },
};
</script>
