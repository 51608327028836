import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    profiles: [
      {
        id: 1,
        "image-url": null,
        name: "Peter Wagner",
        description:
          "M.A. Gesellschafts- und Wirtschaftskommunikation. Erfinder der Spielwirklichkeitsdichte. Schreibt seit 2014 für den schnöden Mammon, macht seitdem aber auch: Startups, Projektmanagement, Geschäftsführung, B2B-Content-Marketing – natürlich alles gleichzeitig und Vollzeit.",
        job: "Job: Geschäftsführer. Berufung: Autor, Kabarettist und Musiker.",
      },

      {
        id: 2,
        "image-url": null,
        name: "Hannes Wagner",
        description:
          "M.F.A. Mediengestaltung und -produktion. Fulldome- und Erklärfilmexperte. Simplifiziert schwierige Sachverhalte seit 2010, macht seitdem aber auch: Projektmanagement, Skript, Kreative Leitung, Animationsfilm und Postproduktion. Leitet den Standort Weimar.",
        job: "Job: Lead Creative. Berufung: Animationsfilmmacher, Fantasyautor, Agent für Kreative.",
      },

      {
        id: 3,
        "image-url": null,
        name: "Bec Irvine",
        description:
          "B.A. Politics, Philosophy, German. Risikofreundin, Debattiermeisterin, Expertin für Gastfreundschaft. Seit 2011 spricht Rebecca mit Menschen und bringt ihnen die Kunst des Debattierens bei, hat ihre Leidenschaft für Craftbeer, Management und Gastfreundschaft zu einem Beruf gemacht.",
        job: "Job: Lead Internal Affairs. Berufung: Altruistin, DJ, Investorin.",
      },

      {
        id: 4,
        "image-url": null,
        name: "Philipp Lange",
        description:
          "B.A. Politics, Philosophy, German. Risikofreundin, Debattiermeisterin, Expertin für Gastfreundschaft. Seit 2011 spricht Rebecca mit Menschen und bringt ihnen die Kunst des Debattierens bei, hat ihre Leidenschaft für Craftbeer, Management und Gastfreundschaft zu einem Beruf gemacht.",
        job: "Job: Lead Internal Affairs. Berufung: Altruistin, DJ, Investorin.",
      },
    ],
    services: [
      {
        id: 1,
        name: "Text",
        description:
          "Copywriting, Screen- & Skriptwriting, Social Media, Content, Blog Content, Magazin, Ghostwriting, Übersetzungen DE/EN/RU (native).",
        button: "Angebot finden & konfigurieren",
      },
      {
        id: 2,
        name: "Konzept",
        description:
          "Content-Strategie B2B oder B2C, Social-Media-Strategie, Formatentwicklung B2B oder B2C, Redaktions- und Themenplanung, Projektmanagement, Audiovisuelle Kreativkonzeption & -produktion.",
        button: "Konzeptservice anfragen",
      },
      {
        id: 3,
        name: "Beratung",
        description:
          "Stimm- und Präsentationstraining, Mediation und Beratung, Kreativberatung & Workshops, Coaching für Kreativentscheider*innen, Soziale Analyse auf wissenschaftlichen Grundlagen, Digitalberatung.",
        button: "Kontaktieren",
      },
    ],
    carroussel: [
      {
        id: 1,
        name: "Copywriter",
        price: 2500,
        description: "Kurze Info zum Social Media Experience Package",
        offerings: [
          { id: 0, quantity: 1 },
          { id: 1, quantity: 2 },
        ],
      },
      {
        id: 2,
        name: "Editor",
        price: 2500,
        description: "Kurze Info zum Social Media Experience Package",
        offerings: [
          { id: 2, quantity: 1 },
          { id: 1, quantity: 2 },
        ],
      },
      {
        id: 3,
        name: "Whatever",
        price: 2500,
        description: "Kurze Info zum Social Media Experience Package",
        offerings: [
          { id: 3, quantity: 1 },
          { id: 4, quantity: 2 },
        ],
      },
      {
        id: 4,
        name: "Whatever",
        price: 2500,
        description: "Kurze Info zum Social Media Experience Package",
        offerings: [
          { id: 2, quantity: 1 },
          { id: 5, quantity: 2 },
        ],
      },
      {
        id: 5,
        name: "Whatever",
        price: 2500,
        description: "Kurze Info zum Social Media Experience Package",
        offerings: [
          { id: 1, quantity: 1 },
          { id: 4, quantity: 2 },
        ],
      },
    ],
    clients: [
      {
        id: 0,
        name: "Treiß Media",
        jobDescription: "Corporate Blogging",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 1,
        name: "INTEGR8 Media GmbH",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 3,
        name: "sustainable natives e.G.",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 4,
        name: "Appmatics",
        jobDescription: "Corporate Blogging",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 5,
        name: "bam! Interactive",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 6,
        name: "Nulight",
        jobDescription: "Seamless Copywriting Experience",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 7,
        name: "Name",
        jobDescription: "Job Description",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
      {
        id: 8,
        name: "Name",
        jobDescription: "Job Description",
        ownerName: "Owner Name",
        ownerBlurb: "Owner Blurb",
        accountDescription:
          "Est cumque porro qui ducimus et voluptas. Ea earum est sed sed voluptatum aut. Sapiente est sint dolorem exercitationem. Fugit corrupti rerum cumque fuga temporibus soluta. Et quia tempora dolore unde voluptas non. Voluptatem officiis itaque nesciunt dolorem quod qui.",
      },
    ],
    offerings: [
      {
        id: 0,
        category: "blogpost",
        name: "Blogpost",
        price: 400,
        quantity: 1,
        chosen: true,
        info: {
          description: "Einfacher Artikel, informativ-unterhaltsam",
          scope: "800-1.200 Wörter",
          links: "mind. 2",
          seo: true,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 1,
        category: "pic1",
        name: "zusätzliches Bild #1",
        price: 25,
        quantity: 1,
        chosen: false,
        info: {
          description: "Header Bild Recherche und Auswahl",
          scope: "",
          links: "",
          SEO: true,
          pieces: "Recherche und Formatanpassung für Publikation",
        },
      },
      {
        id: 2,
        category: "pic2",
        name: "zusätzliches Bild #2",
        price: 25,
        quantity: 1,
        chosen: true,
        info: {
          description: "Ein weiteres Bild je Artikel",
          scope: "",
          links: "",
          SEO: true,
          pieces: "Recherche und Formatanpassung für Publikation",
        },
      },
      {
        id: 3,
        category: "graphic",
        name: "Grafik",
        price: 150,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Eine Grafik für einen Artikel, z.B. Infografik, Karikatur, Flowchart, etc.",
          scope: "",
          links: "",
          SEO: true,
          pieces: "Recherche, Erstellung und Feedbackschleife",
        },
      },
      {
        id: 4,
        category: "deepdive",
        name: "Deep Dive Artikel",
        price: 600,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "In-Depth Artikel: Essay, Kommentar, In-Depth-Artikel, Recherchen & Ressourcen, Anleitung",
          scope: "1.200 - 2.400 Wörter",
          links: "mind.4",
          SEO: false,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 5,
        category: "socialpost",
        name: "Social Media Post",
        price: 125,
        quantity: 1,
        chosen: false,
        info: {
          description: "Dark Post, Anzeige, organischer Feedpost u.a.",
          scope: "90 - 800 Zeichen inkl. visueller Assets",
          links: "",
          SEO: false,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 6,
        category: "storypost",
        name: "Story Post",
        price: 25,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Social Media Story Post für z.B. Facebook oder Instagram",
          scope: "bis zu 5 Story Slides - Konzept, Text",
          links: "",
          SEO: false,
          pieces:
            "Konzept, Recherche, Projektmanagement, 1 Feedbackschleife, Lektorat",
        },
      },
      {
        id: 7,
        category: "hours",
        name: "zusätzliche Stunden",
        price: 86,
        quantity: 1,
        chosen: false,
        info: {
          description:
            "Weiterführendes Projektmanagement, andere Textformate, u.a.",
          scope: "",
          links: "",
          SEO: true,
          pieces: "",
        },
      },
    ],
    request: [],
    shownInfo: {},
    showItemsMobile: false,
    showInfoMobile: false,
    showDetailModal: false,
    showCookieBanner: false,
    visibleChapter: "you-tab",
    visibleChapterLegal: "imprint-tab",
    visibleChapterMain: "services-tab",
    globalOverflow: true,
    overlayOpen: false,
    generatorActive: false,
  },
  getters: {
    getChosenOfferings(state) {
      const chosenOfferings = state.offerings.filter(
        (offering) => offering.chosen === true
      );
      return chosenOfferings;
    },
    getOfferingById: (state) => (id) => {
      return state.offerings.find((offering) => offering.id === id);
    },
    getOfferingByCategory: (state) => (category) => {
      return state.offerings.find((offering) => offering.category === category);
    },
    getShownInfo(state) {
      return state.shownInfo;
    },
    getCookieBanner(state) {
      return state.showCookieBanner;
    },
    getVisibleChapter(state) {
      return state.visibleChapter;
    },
    getVisibleChapterLegal(state) {
      return state.visibleChapterLegal;
    },
    getVisibleChapterMain(state) {
      return state.visibleChapterMain;
    },
  },
  mutations: {
    getObjIndex(state, id) {
      return state.offerings.findIndex((offering) => offering.id === id);
    },
    addToList(state, id) {
      //Find index of specific object using findIndex method.
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );

      //Update object's name property.
      state.offerings[objIndex].chosen = true;
    },
    deleteFromList(state, id) {
      //Find index of specific object using findIndex method.
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );

      //Update object's name property.
      state.offerings[objIndex].chosen = false;
    },

    setInfo(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );

      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },

    addQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      state.offerings[objIndex].quantity++;

      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },

    substractQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      if (state.offerings[objIndex].quantity > 1) {
        state.offerings[objIndex].quantity--;
      }
      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },
    resetQuantity(state, id) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === id
      );
      if (state.offerings[objIndex].quantity > 1) {
        state.offerings[objIndex].quantity = 1;
      }
      // update shownInfo for DetailModal!
      state.shownInfo = Object.assign({}, state.offerings[objIndex]);
    },
    changePrice(state, payload) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === payload.id
      );

      console.log(payload.amount);
      state.offerings[objIndex].price = payload.amount; // should be filtered for floats and absurd amounts
    },

    enterQuantity(state, payload) {
      let objIndex = state.offerings.findIndex(
        (offering) => offering.id === payload.id
      );

      state.offerings[objIndex].quantity = payload.amount; // should be filtered for floats and absurd amounts
    },
    toggleMobileList(state) {
      state.showItemsMobile = !state.showItemsMobile;
    },
    toggleMobileInfo(state) {
      state.showInfoMobile = !state.showInfoMobile;
    },
    toggleMobileItemOrInfo(state) {
      state.showItemsMobile = false;
      state.showInfoMobile = false;
    },
    toggleDetailModal(state) {
      state.showDetailModal = !state.showDetailModal;
    },
    acceptCookies(state) {
      state.showCookieBanner = !state.showCookieBanner;
    },
    setVisibleChapter(state, hash) {
      state.visibleChapter = hash;
    },
    setVisibleChapterLegal(state, hash) {
      state.visibleChapterLegal = hash;
    },
    setVisibleChapterMain(state, hash) {
      state.visibleChapterMain = hash;
    },
    toggelOverflow(state) {
      state.globalOverflow = !state.globalOverflow;
    },
    toggleOverlay(state) {
      state.overlayOpen = !state.overlayOpen;
    },
    toggleGenerator(state) {
      state.generatorActive = !state.generatorActive;
    },
  },
  actions: {},
  modules: {},
});
