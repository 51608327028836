<template>
  <div id="team" class="team-container">
    <Profile
      v-for="(profiles, index) in profiles"
      :key="index"
      :profiles="profiles"
    />
  </div>
</template>
<script>
import Profile from "./Profile.vue";

export default {
  name: "Services",
  components: {
    Profile,
  },
  data() {
    return {};
  },
  computed: {
    profiles() {
      return this.$store.state.profiles;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("main-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document.getElementById("team").getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "team-tab");
        }
      }
    },
  },
};
</script>
