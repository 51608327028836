<template>
  <div class="about-header-container-narrow invert">
    <TheHeaderInvert />
    <div class="legal-header-tabed-narrow invert">
      <nav class="nav-tabs-inverted ">
        <a class="invertselected">{{ getActive }}</a>
      </nav>
    </div>
  </div>
</template>

<script>
import TheHeaderInvert from "../TheHeaderInvert.vue";
export default {
  components: {
    TheHeaderInvert,
  },
  computed: {
    getActive() {

      let tab = "Impressum";

      switch (this.$store.getters.getVisibleChapterLegal) {
        case "imprint-tab":
          tab = "Impressum";
          break;
        case "privacy-tab":
          tab = "Datenschutz";
          break;
        case "agb-tab":
          tab = "AGB";
          break;

        default:
          tab = "Das bist Du";
      }
      return tab;
    },
  },
};
</script>
