<template>
  <div class="menu-header-tabed-container">
    <TheHeader />

    <div class="menu-header-tabed large">
      <nav class="menu-header-tabed-links">
        <a
          id="services-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'services-tab' }"
          @click="scrollInto('services')"
        >
          Services
        </a>
        <a
          id="offerings-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'offerings-tab' }"
          @click="scrollInto('offerings')"
        >
          Angebotsgenerator
        </a>
        <a
          id="team-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'team-tab' }"
          @click="scrollInto('team')"
        >
          Team
        </a>
        <a
          id="clients-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'clients-tab' }"
          @click="scrollInto('clients')"
        >
          Kunden
        </a>
        <a
          id="contact-tab"
          class="tabed-menu-item-desktop"
          :class="{ selected: getActive === 'contact-tab' }"
          @click="expanded = !expanded"
          @mouseover="expanded = !expanded"
          @mouseleave="expanded = !expanded"
        >
          Kontakt
        </a>
      </nav>
    </div>
    <ContactRibbon  :expandribbon="this.expanded" />
  </div>
</template>

<script>
import TheHeader from "../TheHeader.vue";
import ContactRibbon from "../content-page/ContactRibbon.vue"


export default {
  name: "MenuHeaderTabed",
  components: {
    TheHeader,
    ContactRibbon,
  },
  data() {
    return {
      selectedId: "services-tab",
      expanded: false,
    };
  },
  computed: {
    getActive() {
      return this.$store.getters.getVisibleChapterMain;
    },
  },
  methods: {
    scrollInto(hash) {
      let element = document.getElementById(hash);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      this.markSelected(hash + "-tab");
      this.$store.commit("setVisibleChapterMain", hash + "-tab");
    },
    markSelected(hash) {
      // remove class from previously selected tab
      document.getElementById(this.selectedId).classList.remove("selected");
      this.selectedId = hash;

      document.getElementById(hash).classList.add("selected");

      console.log(this.selectedId);
    },
  },
};
</script>
