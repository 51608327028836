<template>
  <div class="invert">
    <TheHeaderMobile />
    <LegalHeaderTabed />
    <LegalHeaderNarrow />
    <LegalContent />
  </div>
</template>

<script>
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import LegalHeaderTabed from "../components/legal/LegalHeaderTabed.vue";
import LegalHeaderNarrow from "../components/legal/LegalHeaderNarrow.vue";
import LegalContent from "../components/legal/LegalContent.vue";

export default {
  name: "Home",
  components: {
    TheHeaderMobile,
    LegalHeaderTabed,
    LegalHeaderNarrow,
    LegalContent,
  },
  data() {
    return {
      PageHeader: "Impressum",
    };
  },
};
</script>
