<template>
  <div id="text" class="paragraph-container-invert">
    <div class="paragraph-header-container row">
      <div class="paragraph-header">
        <h3>Datenschutz</h3>
      </div>
    </div>
    <div class="legal-paragraph-content">
      <p>
        <span class="person-bold">Wer wir sind</span>
        <br />
        <br />

        Die Adresse unserer Website ist: https://wirklichkeitsdichte.org. Wir
        sind ein Unternehmen zur Vermittlung von Text- und
        Beratungsdienstleistungen.
        <br />
        <br />
        Die Website dient als nicht monetarisierte Online-Visitenkarte zur
        Anbahnung von Geschäftskontakten und zur Information über unsere
        Projekte. Es können keine Geschäftsabschlüsse über diese Seite getätigt
        werden. Für genauere Informationen oder bei Interesse an kommerziellen
        Interaktionen wenden Sie sich bitte an die genannten Kontakte.
        <br />
        <br />
        <span class="person-bold">Grundlegendes</span>
        <br />
        <br />
        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art,
        den Umfang und den Zweck der Erhebung und Verwendung personenbezogener
        Daten durch den Websitebetreiber Wirklichkeitsdichte zu informieren.
        <br />
        <br />
        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt
        Ihre personenbezogenen Daten vertraulich und entsprechend der
        gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
        Weiterentwicklung dieser Webseite Änderungen an dieser
        Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich
        die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
        <br />
        <br />

        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten”
        oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
        <br />
        <br />

        <span class="person-bold">Zugriffsdaten</span>
        <br />
        <br />

        Wir, der Websitebetreiber, erheben keine Daten aufgrund unseres
        berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO).
        <br />
        <br />
        Im Falle einer zukünftigen Datenerhebung werden diese für maximal 7 Tage
        gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt
        aus Sicherheitsgründen, um z.B. Missbrauchsfälle aufklären zu können.
        Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von
        der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
        <br />
        <br />

        <span class="person-bold">Reichweitenmessung & Cookies</span>
        <br />
        <br />
        Diese Website verwendet Cookies zur pseudonymisierten
        Reichweitenmessung, die entweder von unserem Server oder dem Server
        Dritter an den Browser des Nutzers übertragen werden. Bei Cookies
        handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert
        werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von
        Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser
        Website. 
        <br />
        <br />
        
        Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung
        auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser
        Dateien hier widersprechen: 
        <br />
        <br />
        Cookie-Deaktivierungsseite der
        Netzwerkwerbeinitiative: http://optout.networkadvertising.org/?c=1#!/
        Cookie-Deaktivierungsseite der US-amerikanischen Website: http://
        optout.aboutads.info/?c=2#!/ Cookie-Deaktivierungsseite der europäischen
        Website: http:// optout.networkadvertising.org/?c=1#!/ Gängige Browser
        bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist
        nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne
        Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen
        vornehmen.
        <br />
        <br />

         <span class="person-bold">Erfassung und Verarbeitung personenbezogener Daten</span>
        
        <br />
        <br />
        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur
        dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in
        die Datenerhebung einwilligen. 
        <br />
        <br />
        Als personenbezogene Daten gelten
        sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen
        und welche zu Ihnen zurückverfolgt werden können – also beispielsweise
        Ihr Name, Ihre E-Mail-Adresse und Telefonnummer. 
        <br />
        <br />
        Diese Website können
        Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur
        Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne
        Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
        Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der
        Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind
        Rückschlüsse auf Ihre Person nicht möglich. 
        <br />
        <br />
        
         <span class="person-bold">Umgang mit Kontaktdaten</span>
        <br />
        <br />
        
        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
        Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert,
        damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
        zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten
        nicht an Dritte weitergegeben. 
        <br />
        <br />
        
        <span class="person-bold">Umgang mit Kommentaren und Beiträgen</span> 
        <br />
        <br />
        
        Auf
        dieser Seite werden keine Kommentar- oder Beitragsfunktionen angeboten.

        <br />
        <br />
        <span class="person-bold">Google Analytics</span> 
        <br />
        <br />
        
        Diese Website nutzt aufgrund unserer berechtigten
        Interessen zur Optimierung und Analyse unseres Online-Angebots im Sinne
        des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher
        von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
        USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ –
        Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die
        Cookies gesammelten Informationen werden im Regelfall an einen Google-
        Server in den USA gesandt und dort gespeichert. 
        <br />
        <br />
        Google LLC hält das
        europäische Datenschutzrecht ein und ist unter dem
        Privacy-Shield-Abkommen zertifiziert: https://www.privacyshield.gov/
        participant?id=a2zt000000001L5AAI&status=Active 
        <br />
        <br />
        
        Auf dieser Website
        greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb
        der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in
        den anderen Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen
        wird die IP-Adresse zunächst ungekürzt in die USA an einen Server von
        Google übertragen und dort gekürzt. Durch diese Kürzung entfällt der
        Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IP-Adresse
        des Nutzers wird nicht mit anderen von Google gespeicherten Daten
        kombiniert. 
        <br />
        <br />
        
        Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung,
        welche wir als Websitebetreiber mit der Google Inc. geschlossen haben,
        erstellt diese mithilfe der gesammelten Informationen eine Auswertung
        der Websitenutzung und der Websiteaktivität und erbringt mit der
        Internetnutzung verbundene Dienstleistungen. 
        <br />
        <br />
        Die von Google in unserem
        Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres
        Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um
        Reports über die Aktivität auf der Website zu erstellen, um unser
        Online-Angebot zu verbessern. 
        <br />
        <br />
        
        Sie haben die Möglichkeit, die Speicherung
        der Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser
        entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass
        Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen
        können, wenn Ihr Browser keine Cookies zulässt. 
        <br />
        <br />
        Weiterhin können Sie
        durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten
        Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet
        und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem
        entsprechenden Plugin: https://tools.google.com/ dlpage/gaoptout?hl=de

        <br />
        <br />
        Alternativ verhindern Sie mit einem Klick auf diesen Link OptOut, dass
        Google Analytics innerhalb dieser Website Daten über Sie erfasst. Mit
        dem Klick auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr
        Browser muss die Speicherung von Cookies also hierzu grundsätzlich
        erlauben. Löschen Sie Ihre Cookies regelmäßig, ist ein erneuter Klick
        auf den Link bei jedem Besuch dieser Website vonnöten. 
        <br />
        <br />
        
        <span class="person-bold">Hier finden Sie
        weitere Informationen zur Datennutzung durch die Google Inc.:</span>

        <br />
        <br />
        https://policies.google.com/privacy/partners?hl=de (Daten, die von
        Google-Partnern erhoben werden)
        https://adssettings.google.de/authenticated (Einstellungen über Werbung,
        die Ihnen angezeigt wird)
        https://policies.google.com/technologies/ads?hl=de (Verwendung von
        Cookies in Anzeigen) 
        <br />
        <br />
        
        <span class="person-bold">Rechte des Nutzers</span> 
        <br />
        <br />
        
        Sie haben als Nutzer das Recht,
        auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche
        personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem
        das Recht auf Berichtigung falscher Daten und auf die
        Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten.
        Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität
        geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig
        verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einreichen. 
        <br />
        <br />
        
        
        <span class="person-bold">Löschung von Daten</span> 
        <br />
        <br />
        
        Sofern Ihr Wunsch nicht
        mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B.
        Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung
        Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre
        Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen
        Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht
        durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke
        erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In
        diesem Fall werden die Daten gesperrt und nicht für andere Zwecke
        verarbeitet. 
        <br />
        <br />
        
        
        <span class="person-bold">Widerspruchsrecht</span> 
        <br />
        <br />
        
        
        Nutzer dieser Webseite können von ihrem
        Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer
        personenbezogenen Daten zu jeder Zeit widersprechen. 
        <br />
        <br />

        Wenn Sie eine
        Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person
        gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der
        Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten
        haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich
        bitte an folgende E-Mail-Adresse: 
        <br />
        <br />
        
        <em>info(at)wirklichkeitsdichte.org</em>
        <br />
        <br />


        <span class="person-bold">Externe Inhalte und ausgehende Links</span> 
        <br />
        <br />
        
        Ausgehende Links auf dieser
        Homepage sind rein informativer Natur und linken lediglich zu
        Wissensseiten oder journalistischen Beiträgen, die kostenlos zugänglich
        sind. Bitte beachten, dass wir für die Inhalte dieser Websites keinerlei
        Haftung oder Gewähr übernehmen und dass dieser über eigene
        Datenschutzerklärungen verfügen. 
        <br />
        <br />
        
        <span class="person-bold">Social Media</span>
        <br />
        <br />
        
        Über Links, die als Logo
        dieser Plattformen erkenntlich werden, bieten wir eine Weiterleitung zu
        Social Media Plattformen an. Dazu gehören Facebook und Instagram. Wir
        übernehmen keine Haftung oder Gewähr für dort nicht von uns eingestellte
        oder kuratierte Inhalte. Facebook und Instagram verwenden Cookies (siehe
        oben), die ihr Nutzungsverhalten zum Zwecke der Zielgruppenerstellung
        und der zielgruppengerechten Werbeschaltung aufzeichnen. Bitte lesen Sie
        hierzu die Datenschutzerklärungen der betreffenden Social Media
        Plattformen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("legal-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("legal-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
        let center = viewportHeight / 2;
      let bounding = document.getElementById("privacy").getBoundingClientRect();
      if (bounding.top < center && bounding.bottom > center) {
        this.$store.commit("setVisibleChapterLegal", "privacy-tab");
      }
    },
  },
};
</script>
