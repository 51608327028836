<template>
  <div class="call-to-action-mobile mobile column">
    <div class="plus-offer mobile" v-on:click="toggleMobileItemList()">
      <img src="~@/assets/plus_circle.svg" />
    </div>

    
    <div class="row contact-info-download">
      <div class="cid-element">
        <img src="~@/assets/contact.svg" /><span>Kontaktieren</span>
      </div>
      <div class="cid-element">
        <img src="~@/assets/download.svg" /><span>Info</span>
      </div>
      <div class="cid-element">
        <img src="~@/assets/download.svg" /><span>Download</span>
      </div>
    </div>
    

  </div>
</template>
<script>
export default {
  methods: {
    toggleMobileItemList() {
      this.$store.commit("toggleMobileList");
    },
  },
};
</script>
