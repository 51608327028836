<template>
  <div class="info-mobile"  v-if="opened">
    <div class="info-mobile-backdrop" @click="toggleMobileInfoList"></div>
    <div class="info-mobile mobile column">
      <h3>Ihre Seamless Writing Experience</h3>
      <div class="description">
        <h3 class="item-info-header">Info</h3>
        <p>
          {{ info.description }}
        </p>
        <h3 class="item-info-header">Umfang</h3>
        <p>
          {{ info.scope }}
        </p>
        <h3 class="item-info-header">Links</h3>
        <p>
          {{ info.links }}
        </p>
        <h3 class="item-info-header">SEO?</h3>
        <p>
          {{ info.seo }}
        </p>
        <h3 class="item-info-header">Beinhaltet:</h3>
        <p>
          {{ info.pieces }}
        </p>
      </div>
    </div>
    <div class="close-info-mobile" @click="toggleMobileInfoList()">
      <img src="~@/assets/closebutton_1pt.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoMobile",
  components: {},
  computed: {
    opened() {
      console.log(this.$store.state.showInfoMobile);
      return this.$store.state.showInfoMobile;
    },
    info() {
      if (this.$store.state.shownInfo) {
        return this.$store.state.shownInfo;
      } else return null;
    },
  },
  methods: {
    toggleMobileInfoList() {
      this.$store.commit("toggleMobileInfo");
    },
  }
};
</script>
