<template>
  <div id="wkd" class="about-paragraph long">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">05</div>
      <div class="paragraph-header">
        <h3>Was bitte ist Wirklichkeitsdichte?</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p>
        Wirklichkeitsdichte ist eine sozialtheoretische Analytik und beantwortet
        die Frage: Wie wirklich ist Deine Wirklichkeit in einer spezifischen
        sozialen Situation?
      </p>
      <p>
        Unsere soziale Wirklichkeit ähnelt einem guten Text: Regt sie uns an,
        langweilt sie uns nicht, überfordert sie uns nicht, dann akzeptieren wir
        sie. Texte werden Teil unserer Realität, wenn sie uns Relevantes bieten,
        klar strukturiert sind und ihr Ende in Sicht bleibt – also wenn ihre
        Regeln klar sind. Copies müssen knistern. Emotionale und inhaltliche
        Intensität entscheidet darüber, ob sie in unseren Köpfen bleiben und
        Teil unserer Lebenswirklichkeit werden.
      </p>
      <p>
        Unsere soziale Wirklichkeit ähnelt einem Spiel (Game): Sie hat implizite
        und explizite Regeln, jeder Satz von Regeln bestimmt eine neue
        Teilwirklichkeit. Die Wirklichkeit eines Arbeitsamtbesuches
        unterscheidet sich von der Realität eines Tinder Dates. An der
        Konstruktion jeder Wirklichkeit sind Regeln und Elemente (Raum, Objekte
        und immaterielle Strukturen) ebenso beteiligt wie die in ihr Handelnden,
        deren Fertigkeiten und ihr Wissen über die spezifische soziale
        Situation.
      </p>
      <p>
        Psychologische Theorien wie die Stress-Theorie, die Flow- Theorie und
        andere verhaltenstheoretische Ansätze bestätigen uns, was der
        game-theoretischen Analytik der Wirklichkeitsdichte zugrunde liegt: Wir
        empfinden Wirklichkeit dann als dicht, wenn wir regelmäßig in ihr
        handeln und unsere Handlungen von Bedeutung sind, wenn wir uns in
        unserer Rolle konsistent fühlen.
      </p>
      <p>
        Bei Wirklichkeitsdichte verpflichten wir uns diesem Prinzip und wenden
        es auf kreative Prozesse an, um unseren Kunden den besten Content für
        ihre Zielgruppen zu liefern, einzigartige Beratungen und
        tellerranderweiternde Analytiken anzubieten.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document.getElementById("wkd").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "wkd-tab");
      }
    },
  },
};
</script>
