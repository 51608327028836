<template>
  <div class="call-to-action">
    <div class="cid-element">
      <img src="~@/assets/contact.svg" /><span>Kontaktieren</span>
    </div>
    <div class="cid-element info-icon" @click="toggleInfoMobile">
      <img src="~@/assets/info.svg" /><span>Info</span>
    </div>
    <div class="cid-element">
      <img src="~@/assets/download.svg" /><span>Speichern</span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toggleInfoMobile() {
      this.$store.commit("toggleMobileInfo");
    },
  },
};
</script>
