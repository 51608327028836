<template>
  <div class="cookie-banner"
  :class="{ 'cookie-banner-accepted': accepted === true }">
    <h1>Cookies</h1>
    <p>
      Wir verwenden nur notwendige Cookies und ausschließlich bei unserem
      Angebotsgenerator.
    </p>
    <a @click="accept()">Akzeptieren</a>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  components: {},
  data() {
    return {
    };
  },
  computed: {
      accepted(){
          console.log( this.$store.getters.getCookieBanner);
          return this.$store.getters.getCookieBanner;
      }

  },
  methods: {
      accept() {
          console.log("accept!");
          this.$store.commit("acceptCookies");
      }
  }
};
</script>
