<template>
  <div class="landing">
    <div class="scene">
      <div class="letter-m kill">M</div>
      <div class="letter-o kill">o</div>
      <div class="letter-i kill">i</div>
      <div class="letter-n kill">n</div>
      <div class="letter-loud kill">!</div>
    </div>
    <router-link
      to="/scroller"
      tag="img"
      title="wkd logo"
      src="~@/assets/logo.png"
    >
    </router-link>

    <h3>wirklichkeitsdichte</h3>
    <div class="landing-blurb">
      Die Agentur für textbasierten Content und wirklichkeitsstiftende
      Maßnahmen.
    </div>

    <svg width="0" height="0">
      <filter id="kill">
        <feColorMatrix
          type="matrix"
          result="red_"
          values="4 0 0 0 0
              0 0 0 0 0 
              0 0 0 0 0 
              0 0 0 1 0"
        />
        <feOffset in="red_" dx="2" dy="0" result="red" />
        <feColorMatrix
          type="matrix"
          in="SourceGraphic"
          result="blue_"
          values="0 0 0 0 0
              0 3 0 0 0 
              0 0 8 0 0 
              0 0 0 1 0"
        />
        <feOffset in="blue_" dx="-9" dy="-8" result="blue" />
        <feBlend mode="multiply" in="red" in2="blue" />
      </filter>
    </svg>

    <CookieBanner />
  </div>
</template>

<script>
import CookieBanner from "../components/CookieBanner.vue";
export default {
  name: "Landing",
  components: {
    CookieBanner,
  },
  data() {
    return {};
  },
};
</script>
