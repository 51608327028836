<template>
  <div @click="toggleOverlay()">
    <router-link to="/scroller" tag="div" class="close-about" v-if="overlayopen">
      <img src="~@/assets/cross.svg" @click="toggleOverlay()"/>
    </router-link>
    <router-link to="/about" tag="div" class="column header-main large">
      <img
        title="wkd logo"
        src="~@/assets/logo.png"
      />
      <h1> WIRKLICHKEITSDICHTE </h1>
    </router-link>
  </div>
</template>

<script>
export default {
  computed:{
    overlayopen(){
      return this.$store.state.overlayOpen;
    }
  },
  methods: {
    toggleOverlay(){
      console.log("toggle cross: " + this.$store.state.overlayOpen);
      this.$store.commit('toggleOverlay');
    }
  }
}
</script>
