<template>
  <div
    id="clients"
    ref="container"
    class="client-container"
    :class="{ 'client-container-expanded': expand === true }"
  >
    <ClientColumn
      v-for="(clients, index) in clients"
      :key="index"
      :clients="clients"
    />

    <ClientExpandFooter :button="button" @expand-clients="expandClientView" />
  </div>
</template>
<script>
import ClientColumn from "./ClientColumn.vue";
import ClientExpandFooter from "./ClientExpandFooter.vue";

export default {
  name: "ClientContent",
  components: {
    ClientColumn,
    ClientExpandFooter,
  },

  data() {
    return {
      expanded: false,
      button: true,
    };
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
    expand() {
      return this.expanded;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("clients")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "clients-tab");
        }
      }
    },
    expandClientView(expand) {
      this.expanded = expand;
    },
  },
};
</script>
