<template>
  <div id="text" class="about-paragraph long">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">02</div>
      <div class="paragraph-header">
        <h3>Warum so viel Text</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p><b>I want to believe</b></p>
      <p>
        I want to believe Visuelles Storytelling hat seine Grenzen da, wo
        Menschen anfangen zu denken. Das Gehirn Eurer Zielgruppen ist nach
        Jahrzehnten der Dauerbeschallung mit einem nativen Bullshit-Detektor
        ausgestattet. Wir versuchen Euch nicht mit bunten Bildern einzulullen,
        mit fancy Stockfotos oder poppigen Grafiken. Wir glauben an die Macht
        des Wortes, an Kohärenz, an handwerkliches Können und Fachwissen, an
        Erfahrung und Talent.
      </p>
      <p>
        Wir sind überzeugt davon, dass Texte eine Seele haben müssen, eine
        Stimme, damit sie Menschen erreichen. Wir finden, dass die meisten
        Marken gleich klingen, sich zu viel Mühe geben cool und seriös zu sein,
        Deinen Freund zu mimen, die ultimative Lösung zu bieten. Denn dabei
        verheimlichen sie uns, was sie tatsächlich sind: Unternehmen. Wir sind
        überzeugt, dass das nicht so sein muss, dass eure Marke ein Chor aus
        verschiedenen Stimmen sein darf, dass sie Autorinnen nicht in ein
        Korsett pressen, Kreativität nicht unterdrücken muss, Vielfältigkeit
        feiern kann. Dafür braucht es Mut. Wenn ihr den habt, dann vergesst Euer
        Marketingdeutsch und -englisch, lasst den Bullshit auf der Alm und
        kontaktiert uns.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document.getElementById("text").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "text-tab");
      }
    },
  },
};
</script>
