<template>
  <div class="offering-column">
    <div class="offering-column-top">
      <h1>{{ services.name }}</h1>
      <div class="offering-column-illustration">platzhalter</div>
      <p>
        {{ services.description }}
      </p>
    </div>
    <div class="offering-column-button">{{ services.button }}</div>
  </div>
</template>

<script>
export default {
  props: {
    services: Object,
  },
};
</script>
