<template>
  <div class="carroussel">
    <TheHeaderMobile />
    <MenuHeaderTabed />
    <CarrousselContent />
  </div>
</template>

<script>
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import CarrousselContent from "../components/carroussel/CarrousselContent.vue";

export default {
  name: "Carroussel",
  components: {
    TheHeaderMobile,
    MenuHeaderTabed,
    CarrousselContent,
},
  data() {
    return {};
  },
};
</script>
