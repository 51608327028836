<template>
  <div
    class="client-expand-footer"
    :class="{ 'client-expand-footer-relative': expanded === true }"
  >
    <div
      class="expand-button"
      :class="{
        'expand-button-closed': this.button === false,
        'expand-button-inverse': expanded === true,
      }"
      @click="expandSwitcher()"
    >
      {{ this.expandText }}
    </div>
    <div id="contact" class="expand-contact">
      <div class="phone">+49 157 322 322 91</div>
      <div class="mail">info@wirklichkeitsdichte.org</div>
      <div class="linkedin">LinkedIn</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientExpandFooter",
  components: {
  },
  props: {
    button: Boolean,
  },
  data() {
    return {
      expandText: "Mehr lesen",
      expanded: false,
    };
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
  },
  methods: {
    expandSwitcher() {
      if (!this.expanded) {
        this.expanded = !this.expanded;
        this.expandText = "Weniger lesen";
        this.$emit("expand-clients", true);
      } else {
        this.expanded = !this.expanded;
        this.expandText = "Mehr lesen";
        this.$emit("expand-clients", false);
      }
    },
  },
};
</script>
