<template>
  <div id="textheer" class="about-paragraph long">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">06</div>
      <div class="paragraph-header">
        <h3>Textheer</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p>
        Unser Schreibenden kommen aus allen möglichen Disziplinen und sind
        deswegen gut, weil sie eben nicht nur schreiben. Sondern, weil sie ein
        Leben jenseits der Tastatur führen.
      </p>
      <p>
        Wir haben Copywriter-Schriftstellerinnen, Drehbuchschreiber-
        Filmemacherinnen, Komponisten-Pädagoginnen, IT- Bierspezialistinnen,
        Liederschreiber- Kommunikationsexpertinnen, Kryptoinvestor-
        Grafikdesignerinnen, Steuerfachinteressierte, Erklärbären und Bärinnen,
        Synchronsprecher-Datenschutzbeauftragte,
        Unternehmensorganisationstherapeutinnen und mehr.
      </p>
      <p>
        Durch Sie können wir eine ungeheure Menge an Themen abdecken und in
        knackigen Short Copys, tief greifenden Artikeln oder witzigen
        Social-Media-Posts zu dem kristallisieren, was Eure Kund*innen gern
        lesen wollen.
      </p>
      <p>
        Wir zahlen einen festen, attraktiven Stundenlohn für unsere
        Freelancer*innen und Angestellte. Bei uns gibt es für Schreibende kein
        40-Stundenwochen oder ständige Verfügbarkeit. Dadurch gewährleisten wir
        die inhaltliche Qualität unserer Texte. Unsere Projektmanager tun den
        Rest. Bei uns gilt: Dein Leben geht vor. Klare Ansagen zählen bei uns.
        Wir vertrauen uns gegenseitig und unser Wort gilt. Denn Worte sind uns
        wichtig.
      </p>
      <p>
        Bei Wirklichkeitsdichte erhältst Du Zugriff auf eine Vielzahl
        verschiedener Textarten, Stile und Formen. Als Expert*innen für Text,
        Geschichten und textbasierten Content stehen wir beratend und ausführend
        zur Seite. In unserem Textheer finden sich erfahrene Copywriter*innen
        für Short- und Longcopy, Autor*innen für Drehbücher und literarische
        Werke, diskrete Ghostwriter*innen für akademische Arbeiten und
        politische Reden, erfahrene Storyteller*innen, Lektor*innen,
        Übersetzer*innen. Bei uns kriegst Du alles, was Buchstaben hat oder
        darauf basiert. Das Textheer ist international, bei uns buchstabieren
        deutsche, russische und englische Muttersprachler*innen. Unser Netzwerk
        erstreckt sich von Berlin nach Leipzig, von Melbourne nach New York.
      </p>

      <div class="network">
        <h3>Unser Netzwerk</h3>
        <ul>
          <li>
            <span class="person-bold">Bec Irvine</span> – Co-Foundessa, Copywriterin, Long- und Shortcopy,
            Blog, Kulinaristin, Katzenliebhaberin, Bierconnaisseuse
          </li>
          <li>
            <span class="person-bold">Viktor Terjaew</span> – Drehbuchautor, Poet, Texter, Übersetzer,
            Slawist, Gourmet, Psychonaut
          </li>
          <li>
            <span class="person-bold">Benjamin Winsel</span> – Projektmanager, IT-Spezialist,
            Kultur-facili-täter, Konzept, Webdevelopment
          </li>
          <li>
            <span class="person-bold">Julianne Cordray</span> – Kunstjournalistin, Übersetzerin,
            Redakteurin
          </li>
          <li>
            <span class="person-bold">Hannes Wagner</span> – Co-Firmer, Produzent, Erklärfilmspezialist,
            Skript, Konzept, Animationsfilm, Postproduction (Film)
          </li>
          <li><span class="person-bold">Georg Voigt</span> – Texter, Poet, Urtümler, Bierconnaiseur </li>
          <li><span class="person-bold">Peter Wagner</span> – Gründi, Head of Text, Ghostwriter, B2B Content Specialist, alles mit Buchstaben</li>
          <li><span class="person-bold">Philipp Lange</span> – Grafikdesign, Text</li>
          <li><span class="person-bold">Ina Falkner</span> – Copywriter, Marketing und Sales, Lektorat, eiskalte Profi </li>
          <li><span class="person-bold">Gary Hirche</span> – Komposition, Musikproduktion</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
       const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let bounding = document.getElementById("textheer").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "textheer-tab");
      }
    },
  },
};
</script>
