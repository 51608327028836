<template>
  <div id="you" class="about-paragraph">
    <div class="paragraph-header-container row">
      <div class="paragraph-chapter-id">01</div>
      <div class="paragraph-header">
        <h3>Das bist Du</h3>
      </div>
    </div>
    <div class="paragraph-content">
      <p>
        Du bist hier, weil Du nach einer freiberuflichen Texterin gesucht hast,
        einem Copywriter in Berlin, einer Freelancerin für Textaufgaben, einem
        Ghostwriter für Deine nächste Rede, einem Screenwriter für Deine nächste
        Produktion, nach Konzepter*innen für Kampagnen oder
        B2B-Content-Spezialist*innen. Du hast uns gefunden.
      </p>
      <p>
        Wirklichkeitsdichte ist ein kleines Heer von Schreibenden, von
        Kreativen, die nicht alles visuell denken, sondern von der Geschichte
        her. Wir nehmen Storytelling ernst, denn am Anfang war das Wort.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("about-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("about-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      let bounding = document.getElementById("you").getBoundingClientRect();
      if ((viewportHeight - bounding.top) > 0 && bounding.bottom > 0) {
        this.$store.commit("setVisibleChapter", "you-tab");
      }
    },
  },
};
</script>
