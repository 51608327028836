<template>
  <div id="text" class="paragraph-container-invert">
    <div class="paragraph-header-container row">
      <div class="paragraph-header">
        <h3>AGB</h3>
      </div>
    </div>
    <div class="legal-paragraph-content">
      <p>Platzhalter</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.handleScroll();
    document
      .getElementById("legal-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("legal-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
        let center = viewportHeight / 2;
      let bounding = document.getElementById("agb").getBoundingClientRect();
      if (bounding.top < center && bounding.bottom > center) {
        this.$store.commit("setVisibleChapterLegal", "agb-tab");
      }
    },
  },
};
</script>
