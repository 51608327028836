<template>
  <div id="about-container" class="about-container">
    <You id="you" />
    <Texter id="text" />
    <Copywriter id="copywriter" />
    <Textperson id="textperson" />
    <WKD  id="wkd" />
    <Textheer  id="textheer" />
    <WKD18  id="wkd18" />
    <LegalBanner  id="legalbanner" />
  </div>
</template>

<script>
import You from "./You.vue";
import Texter from "./Texter.vue";
import Copywriter from "./Copywriter.vue";
import Textperson from "./Textperson.vue";
import WKD from "./WKD.vue";
import Textheer from "./Textheer.vue";
import WKD18 from "./WKD18.vue";
import LegalBanner from "./LegalBanner.vue";
export default {
  components: {
    You,
    Texter,
    Copywriter,
    Textperson,
    WKD,
    Textheer,
    WKD18,
    LegalBanner,
  },
  computed: {},
  methods: {
  },
};
</script>
