<template>
  <div
    class="carroussel-column"
    v-bind:id="'carroussel-column-' + carroussel.id"
    :class="{ 'carroussel-column-active': active }"
  >
    <div class="carroussel-id">0{{ carroussel.id }}</div>
    <div class="carroussel-column-header">
      <h1>{{ carroussel.name }}</h1>
      <h3>Experience</h3>
    </div>
    <div class="carroussel-price">
      <span>Basispreis</span>
      <span class="carroussel-price-amount">{{ carroussel.price }} €</span>
    </div>
    <div class="carroussel-description">
      {{ carroussel.description }}
    </div>

   
    <div @click="$emit('clicked')" class="caroussel-config-button">Konfigurieren</div>
  </div>
</template>

<script>
export default {
  name: "CarrousselColumn",
  props: {
    carroussel: Object,
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.handleScroll();
    document
      .getElementById("carroussel-column-container")
      .addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  
  methods: {
    handleScroll() {
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      let id = "carroussel-column-" + this.carroussel.id;

      let bounding = document.getElementById(id).getBoundingClientRect();
      let viewportCenter = viewportWidth / 2;
      /*
      console.log("bounding-left: " + bounding.left);
      console.log("bounding-right: " + bounding.right);
      console.log("viewport-center: " + viewportCenter);
      */
      if (bounding.left < viewportCenter && bounding.right > viewportCenter) {
        this.active = true;
        this.$emit('in-view', this.carroussel.id);
      } else {
        this.active = false;
      }
    },
  },
};
</script>
