<template>
  <div
    class="backdrop"
    :class="{ 'backdrop-close': opened === false }"
    @click="toggleDetailModal()"
  />
</template>
<script>
export default {
  name: "Backdrop",
  computed: {
    opened() {
      return (this.$store.state.showDetailModal);
    },
  },
  methods: {
    toggleDetailModal() {
      this.$store.commit("toggleDetailModal");
    },
  },
};
</script>
