<template>
  <div id="services" class="services-container row">
    <OfferingColumn
      v-for="(services, index) in services"
      :key="index"
      :services="services"
    />
  </div>
</template>
<script>
import OfferingColumn from "./OfferingColumn.vue";

export default {
  name: "OfferingContent",
  components: {
    OfferingColumn,
  },
  data() {
    return {};
  },
  computed: {
    services() {
      return this.$store.state.services;
    },
    generatorActive() {
      return this.$store.state.generatorActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (!this.generatorActive) {
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        let bounding = document
          .getElementById("services")
          .getBoundingClientRect();
        if (viewportHeight - bounding.top > 0 && bounding.bottom > 0) {
          this.$store.commit("setVisibleChapterMain", "services-tab");
        }
      }
    },
  },
};
</script>
