<template>
  <div class="contact-ribbon-container" :class="{ colapse: !expanded }">
    <div class="contact-ribbon">
      <div class="phone">+49 157 322 322 91</div>
      <div class="mail">info@wirklichkeitsdichte.org</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactRibbon",
  props: { 
    expandribbon: Boolean, 
  },
  data() {
    return {};
  },
  computed: {
    expanded(){
      return this.expandribbon;
    }
  }
};
</script>
