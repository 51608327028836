<template>
  <div class="paragraph-container-invert first-paragraph">
    <div class="paragraph-header-container row">
      <div class="paragraph-header">
        <h3>Impressum</h3>
      </div>
    </div>
    <div class="legal-paragraph-content">
      
      <p>
        <span class="person-bold">Wirklichkeitsdichte GmbH</span>
        <br>
        Liebenwalder Straße 16A 
        <br>
        HRB 235923 B
        <br>
        <br>
        info@wirklichkeitsdichte.org 
        <br>
        <br>
        +49 157 322 322 91
        <br>
        peter.wagner@wirklichkeitsdichte.org
      </p>
    </div>
  </div>
</template>

<script>
export default {
   mounted() {
    this.handleScroll();
    document
      .getElementById("legal-container")
      .addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document
      .getElementById("legal-container")
      .removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let center = viewportHeight / 2;
      let bounding = document.getElementById("imprint").getBoundingClientRect();
      if (bounding.top < center && bounding.bottom > center) {
        this.$store.commit("setVisibleChapterLegal", "imprint-tab");
      }
    },
  },
};
</script>
