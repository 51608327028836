<template>
  <div
    class="detail-modal-container"
    :class="{ 'detail-modal-close': opened === false }"
  >
    <div
      class="detail-modal"
      :class="{ 'detail-modal-close': opened === false }"
    >
      <div class="detail-modal-top">
        <div class="detail-modal-close-button" @click="toggleDetailModal()">
          <img src="~@/assets/cross_white.svg" />
        </div>
        <div class="detail-modal-header">
          <h3>{{ info.name }}</h3>
          <span>Anzahl</span>
        </div>
        <div class="detail-modal-counter">
          <img
            src="~@/assets/arrow-left.svg"
            @click="decrementOffering(info.id)"
          />
          <span class="item-amount"> {{ info.quantity }} </span>
          <img
            src="~@/assets/arrow-right.svg"
            @click="incrementOffering(info.id)"
          />
        </div>
      </div>

      <div class="detail-modal-bottom">
        <h3>{{ info.info.description }}</h3>
        <p>{{ info.info.scope }}</p>
        <p>
          {{ info.info.pieces }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailModal",
  data() {
    return {};
  },
  computed: {
    opened() {
      console.dir(this.$store.state.showDetailModal);
      return this.$store.state.showDetailModal;
    },
    info() {
      console.log("passiert was!");
      return this.$store.state.shownInfo;
    },
  },
  methods: {
    toggleDetailModal() {
      this.$store.commit("toggleDetailModal");
    },
    incrementOffering(index) {
      console.log("index: " + index);
      this.$store.commit("addQuantity", index);
    },
    decrementOffering(index) {
      console.log("index: " + index);
      this.$store.commit("substractQuantity", index);
    },
  },
};
</script>
