<template>
  <div>
    <TheHeaderMobile />
    <TheHeader />
    <MenuHeaderTabed />
    <ClientContent />
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheHeaderMobile from "../components/TheHeaderMobile.vue";
import MenuHeaderTabed from "../components/content-page/MenuHeaderTabed.vue";
import ClientContent from "../components/clients/ClientContent.vue";


export default {
  name: "Clients",
  components: {
    TheHeader,
    TheHeaderMobile,
    MenuHeaderTabed,
    ClientContent,
    
},
  data() {
    return {};
  },
};
</script>
